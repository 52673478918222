<template>
  <teleport :to="teleportTo">
    <div
      v-if="localShow"
      v-bind="$attrs"
      ref="modal"
      class="modal animate__fadeIn animate--fast"
      :class="modalClass"
      @keydown="handleKeyboardClose"
      @click.self="easyClose ? closeModal('reject', true) : null">
      <div
        class="modal__inner animate__fadeInUpSmall animate--faster"
        :class="{ 'modal__inner--full': isFull, ['modal__inner--' + size]: !isFull }">
        <icon
          v-if="closeButton && !tight"
          class="modal__icon-close"
          :size="1.8"
          color="gray"
          icon="x"
          @click="closeModal('reject', true)" />
        <icon-halo
          v-if="closeButton && tight"
          class="modal__icon-close"
          :size="1.6"
          theme="bg"
          halo-theme="inverse"
          :halo-ratio="0.4"
          icon="x"
          @click="closeModal('reject', true)" />
        <div v-if="title || $slots['title']" class="modal__header">
          <slot name="title" :modal="this">
            <h3 class="modal__title" :class="{ 'modal__title--center': centerTitle }">
              <slot name="title-text">
               <span>
                  {{ titleText }}
               </span>
              </slot>
            </h3>
          </slot>
        </div>

        <div class="modal__body">
          <slot default :modal="this"></slot>
        </div>

        <div v-if="showFooter || $slots['footer']" class="modal__footer">
          <slot name="footer" :modal="this">
            <form-button @click="okClickHandler('reject')">{{
              translate("core.ui.modal.okButtonText")
            }}</form-button>
          </slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import ModalMixin from "@/client/extensions/mixins/baseComponents/modal.js";

export default {
  mixins: [ModalMixin],
  props: {
    teleportTo: {
      type: String,
      default: "#modals"
    },
    full: {
      type: [Boolean, String],
      default: false,
    },
    class: {
      type: [String],
      default: "",
    },
    tight: {
      type: Boolean,
      default: false,
    },
    selfClose: {
      // todo: implement?
      type: [Boolean],
      default: true,
    },
    showHeader: {
      // todo: implement?
      type: [Boolean],
      default: true,
    },
    size: {
      type: String,
      default: "default",
    },
  },
  data: function () {
    return {};
  },
  computed: {
    isFull() {
      return this.full === "true" || this.full === true;
    },
    modalClass() {
      let result = ["z-level-top-2"];

      if (this.localShow) {
        result.push("active");
      }

      if (this.title || this.$slots["title"]) {
        result.push("modal--has-header");
      }

      if (this.showFooter || this.$slots["footer"]) {
        result.push("modal--has-footer");
      }

      if (this.tight) {
        result.push("modal--tight");
      }
      result.push(this.class);
      return result;
    },
  },
  watch: {
    show(newVal) {},
  },
  created() {},
  methods: {
    closeModal(context, fireEvents = true) {
       if (fireEvents) {
        this.$emit("modal:beforeClose", { context: context });
        this.$emit("modal:beforeHide", { context: context });
      }
      this.localShow = false;

      if (fireEvents) {
        this.$emit("modal:closed", { context: context });
        this.$emit("modal:afterHide", { context: context });
        this.$emit("modal:afterClose", { context: context });
      }
    },
    openModal(fireEvents) {
      if (fireEvents) {
        this.$emit("modal:beforeShow");
        this.$emit("modal:beforeOpen");
      }

      this.localShow = true;
      if (fireEvents) {
        this.$emit("modal:afterShown");
        this.$emit("modal:afterOpened");
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
